.events {
    padding: 40px;
}

.update-event-modal {
    width: 800px;
    .md-dialog-content {
        margin: 20px;
    }
    .date-box {
        position: relative;
        margin: 20px;
        .add-button {
            position: absolute;
            right: 0;
            top: 10px;
        }
    }
}

.add-date-modal {
    width: 400px;
    overflow: visible;
    md-dialog-content {
        padding: 20px;
    }
    md-dialog {
        overflow: visible;
    }
}