.structures {
    position: relative;
    .filters {
        width: 20%;

        select{
            width: 180px;
        }
    }
    .actions {
        position: absolute;
        right: 30px;
        top: -35px;
    }
    .my-table {
        width: 80%;
        .navigator {
            text-align: right;
            padding: 10px 0;
            height: 10%;
            .pageNumber {
                display: inline-block;
                text-align: center;
                width: 100px;
            }
        }
    }
    .material-icons {
        cursor: pointer;
    }
}

.structures-form {
    padding: 30px;
    #map {
        height: 400px;
    }
    .img-validation {
        //-ms-transform: rotate(7deg);
        /* IE 9 */
        //-webkit-transform: rotate(7deg);
        /* Chrome, Safari, Opera */
        //transform: rotate(7deg);
        margin: 10px 0;
    }
    .uploadBox {
        input {
            padding: 20px 0;
        }
    }
    .stato {
        padding: 20px 0;
    }
    &.read {
        input {
            border: none;
        }
    }
    .events-box {
        position: relative;
        margin: 20px;
        .add-button {
            position: absolute;
            right: 0;
            top: 10px;
        }
    }
}

.structures-marker {
    .marker-container{
        text-align: center;
    }
    .marker-preview{
        display: inline-block;
        position: relative;
        height: 67px;
        outline: none;
    }
}