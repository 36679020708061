.users {
    position: relative;
    .actions {
        position: absolute;
        right: 30px;
        top: -35px;
    }
    .table-cell {
        width: 100%;
    }
    .filter-cell {
        min-width: 350px;
    }
}

.update-user-modal {
    width: 800px;
    .md-dialog-content {
        margin: 20px;
    }
}
