.login {
    width: 100%;
    height: 100%;
    background-color: $yellow;
    position: relative;
    overflow: hidden;
    .box {
        margin: auto;
        padding: 15px 45px 45px 45px;
        position: relative;
        top: 100px;
        z-index: 1;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
        top: 100px;
        width: 400px;
        height: 300px;
        background-color: white;
        overflow: hidden;
    }
    .login-form {
        margin-top : 20px;
        input {
            font-family: "Roboto", sans-serif;
            outline: 0;
            background: #f2f2f2;
            width: 100%;
            border: 0;
            margin: 0 0 15px;
            padding: 15px;
            box-sizing: border-box;
            font-size: 14px;
        }
        button {
            font-family: "Roboto", sans-serif;
            text-transform: uppercase;
            outline: 0;
            background: #FF7F00;
            width: 100%;
            border: 0;
            padding: 15px;
            color: #FFFFFF;
            font-size: 14px;
            -webkit-transition: all 0.3 ease;
            transition: all 0.3 ease;
            cursor: pointer;
        }
    }
}