.pr-img {
    display: inline-block;
    min-width: 150px;
    text-align: center;
    //margin: 0 10px;
    .img {
        display: inline-block;
        border: 2px solid red;
        position: relative;
        height: 100px;
        outline: none;
        &.validated {
            border-color: green;
        }
        img {
            margin: 0 3px;
            padding: 5px;
            position: relative;
            height: 100px;
        }
        &:hover .detail-overlay {
            cursor: pointer;
            display: table;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 10;
            background-color: lightblue;
            //opacity: 0.8;
            color: white;
            font-size: 16px;
            font-weight: bold;
            &:before {
                content: 'Dettaglio';
                display: table-cell;
                vertical-align: middle;
            }
        }
        
    }
    .actions {
        display: table;
        width: 100%;
        padding: 0 10px;
        >* {
            display: table-cell;
            text-align: center;
            i {
                cursor: pointer;
            }
        }
    }
}


/* Style the Image Used to Trigger the Modal */

#myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

#myImg:hover {
    opacity: 0.7;
}


/* The Modal (background) */

.modal {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/ opacity */
}


/* Modal Content (Image) */

.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}


/* Caption of Modal Image (Image Text) - Same Width as the Image */

#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}


/* Add Animation - Zoom in the Modal */

.modal-content,
#caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0)
    }
    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {
    from {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}


/* The Close Button */

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}


/* 100% Image Width on Smaller Screens */

@media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%;
    }
}