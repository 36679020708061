html,
body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

#main {
    height: 100%;
    width: 100%;
}

button {
    border: none;
    background-color: transparent;
}

.home {
    display: table;
    height: inherit;
    width: inherit;
    .header {
        display: table-row;
    }
    .menu {
        display: table-row;
    }
    .content {
        display: table-row;
        height: inherit;
        width: inherit;
    }
}

.md-dialog-container {
    position: fixed;
}

#pac-input {
    position: relative;
}

[class *="col-"] {
    padding: 5px;
}

md-dialog {
    max-width: none;
}

.md-toolbar-tools {
    background-color: orange;
}

md-nav-bar.md-default-theme md-nav-ink-bar,
md-nav-bar md-nav-ink-bar,
{
    background-color: orange;
    color: orange;
}

.md-button.md-default-theme.md-accent,
.md-button.md-accent {
    color: orange;
}

.search-layout {
    display: table;
    //table-layout: fixed;
    width: 100%;
    height: 100%;
    .row {
        height: 100%;
        width: 100%;
        padding: 20px;
        >* {
            display: table-cell;
            vertical-align: top;
            padding: 5px;
        }
    }
}

.fc-content {
    cursor: pointer;
}


.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #FF7F00; /* Blue */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }